import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subject, map } from 'rxjs';
import { JwtService } from '..';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { VisaSearch } from '../../views/ui/visa-search/visa-search.component';
import { RefundRequest } from '../../views/ui/transport-post-ticketing/transport-post-ticketing.component';
import { TransferDetails } from './umrah.service';
import { SelectedRoom, SelectedRooms } from '../components/rooms-info/rooms-info.component';
import Swal from 'sweetalert2';
import { Guest } from '../components/umrah-booking/umrah-booking.component';
import { ShoppingDropdownData } from '../../app.component';
import {
  Brands,
  SearchRequestShopping,
  ShoppingBookingPayload,
  ShoppingDetails,
  ShoppingPricePayload,
  ShoppingPriceResponse,
  ShoppingSearch,
  ShoppingSearchRes,
  ShoppingTypeDetailsRes,
  ShoppingTypePriceDetails,
  ShoppingTypes,
} from '../models/shopping';
import { TranslateService } from '@ngx-translate/core';
import { Tenant } from '../../AppEnums/Tenant';
import { FareTag, SuppRateType } from '../../AppEnums/FareTags';
declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private shoppingDropdownURL = 'GetByMasterAnc/26';
  private shoppingProductTypeURL = 'types/GetByAncDetId';
  private shoppingProductSubTypeURL = 'subtypes/getByAncTypeId';
  private shoppingB2cURL = `${environment.API_Hotels_BaseUrlFC}ShoppingB2C`;
  private BASE_URL = environment.transferB2CBaseURI;
  private subject = new Subject<any>();
  private getprogressval = new Subject<any>();
  private getorderval = new Subject<any>();
  private setTimerSubject = new Subject<any>();
  baseUrl: string = environment.baseUrl;
  users = 'users';
  serviceType = new EventEmitter();
  postTicketingURL = environment.postTicketingURL;
  postTicketingRefundURL = environment.refundTicketingURL;
  shoppingBaseURL = `${environment.shoppingURI}`;
  fc_URI = environment.API_Hotels_BaseUrlFC;
  ROE: any;
  baseROE: any;
  servicesPDFRoute = {
    hotel: 'GetHotelVoucherPDF',
    otherServices: 'GetOtherServicesVoucherPDF',
    package: 'GetPackagesVoucherPDF',
    transport: 'GetTransferVoucherPDF',
    shopping: 'GetShoppingVoucherPDF',
  };
  invoicePDFRoute: DownloadInvoicePDF = {
    Hotel: 'GetHotelInvoicePDF',
    Umrah: 'GetOtherServicesInvoicePDF',
    Hajj: 'GetOtherServicesInvoicePDF',
    Packages: 'GetPackagesInvoicePDF',
    Transfer: 'GetTransferInvoicePDF',
    Shopping: 'GetShoppingInvoicePDF',
  };

  // shoppingDropdown: any[] = [];
  private shoppingDropdown = new Subject<ShoppingDropdownData[]>();
  private shoppingProducts = new Subject<BrandsNCat[]>();

  private activeNavItem = new Subject<string>();
  private shoppingSearch = new Subject<ShoppingSearch>();

  constructor(
    private jwtService: JwtService,
    private titleService: Title,
    private httpClient: HttpClient,
    private router: Router,
    private translate: TranslateService,
  ) {}

  getCurrentLanguage(): CurrentLanguage {
    const lang: CurrentLanguage = localStorage.getItem('wajedLang')
      ? JSON.parse(localStorage.getItem('wajedLang') || '{}')
      : {
          languageCode: 'ar',
        };
    return lang;
  }

  patternMatchRegex(inputVal: any, InputType: string) {
    let RegEx: any = '';
    if (InputType === 'email') {
      RegEx = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,4}$');
    } else if (InputType === 'phoneNumber' || InputType === 'mobile') {
      RegEx = new RegExp('^((\\+91-?)|0)?[0-9]{9,11}$');
      // RegEx = new RegExp('^((\\+91-?)|0)?[0-9]{10}$');
      // RegEx = new RegExp('^((?!(0))[0-9]{9,11})$');
    } else if (InputType === 'strongPasswordCheck') {
      RegEx = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[^A-Za-z0-9])(?=.*?[0-9]).{8,}$');
    }
    const validRex = RegEx.test(inputVal);
    return validRex;
  }

  isEVisaAvailable(): boolean {
    const visaSearch: VisaSearch | null = localStorage.getItem('visaSearch')
      ? JSON.parse(localStorage.getItem('visaSearch')!)
      : null;
    if (!visaSearch) return true;
    if (visaSearch.countryOfVisit.nameCountry.toLowerCase() === 'switzerland') return false;

    return true;
  }

  lowerCaseBoardType(boardType: string): string {
    return boardType.split(' ').join('').toLowerCase();
  }

  /**
   * Create a modified fare array based on the suppRateType.
   * @param fare Fare array
   * @param suppRateType SuppRateType
   * @returns Modified fare array
   */
  calculateFareBaseOnSuppRate(fares: Fare[], suppRateType: SuppRateType) {
    //NOTE - Fare Calculation - scenarios
    //When the suppRateType is C.R, Base fare would be = B.Fare - Commission
    //When the suppRateType is N.R, Base fare would be = B.Fare + TotalMarkup
    //When the suppRateType is F.B, Base fare would be = B.Fare

    const markup =
      suppRateType === SuppRateType.NetRate
        ? fares.find((f) => f.fareTag === FareTag.TotalMarkup)
        : { amount: 0 };
    const commission =
      suppRateType === SuppRateType.CommissionRate
        ? fares.find((f) => f.fareTag === FareTag.Commission)
        : { amount: 0 };

    const modifiedFare = fares.map((f) => {
      if (suppRateType === SuppRateType.NetRate) {
        if (f.fareTag === FareTag.BaseFare && markup) {
          f.amount += +markup.amount;

          return f;
        }
      }

      if (suppRateType === SuppRateType.CommissionRate) {
        if (f.fareTag === FareTag.BaseFare && commission) {
          f.amount -= commission.amount;

          return f;
        }
      }

      return {
        ...f,
      };
    });
    console.log('🚀 ~ GlobalService ~ modifiedFare ~ modifiedFare:', modifiedFare);

    return modifiedFare;
  }

  patternInitialValidity(inputVal: any) {
    let RegEx: any = '';
    RegEx = new RegExp('^((\\+91-?)|0)?[0-9]{}$');
    const validRex = RegEx.test(inputVal);
    return validRex;
  }

  /**
   * Check if the length of any selected room is greater than zero.
   *
   * @param {SelectedRooms} selectedRooms - object containing selected rooms
   * @return {boolean} whether any selected room has a length greater than zero
   */
  public isLengthIsGreaterThanZero(selectedRooms: SelectedRooms): boolean {
    const isLengthIsGreaterThanZero = Object.values(selectedRooms).some((sr) => sr.length > 0);
    return isLengthIsGreaterThanZero;
  }

  mergeAmounts(array: Fare[]): Fare[] {
    const uniqueObjects: { [key: string]: Fare } = {};

    array.forEach((obj) => {
      const key = JSON.stringify(obj.fareTag);

      if (uniqueObjects[key]) uniqueObjects[key].amount += obj.amount;
      else uniqueObjects[key] = { ...obj };
    });

    return Object.values(uniqueObjects);
  }

  //TODO - change the return response so adult count and string is in two different keys
  public createMaxPersons = (searchOccupancy: string): string => {
    let adults: number = 0;
    let childrens: number = 0;
    let infant: number = 0;
    const translatedValues = this.translate.instant([
      'adult',
      'adults',
      'child',
      'children',
      'infant',
      'infants',
    ]);

    searchOccupancy.split(',').forEach((occupancy: string) => {
      occupancy.split('+').forEach((o: string, i: number) => {
        if (i === 0) adults += Number(o);
        if (i === 1) childrens += Number(o);
        if (i === 2) infant += Number(o);
      });
    });

    return `${adults} ${adults === 1 ? 'Adult' : 'Adults'} ${childrens} ${
      childrens === 1 ? 'Child' : 'Children'
    } ${infant} ${infant === 1 ? 'Infant' : 'Infants'}`;
  };

  callMin(paxType: string) {
    let date = new Date();
    if (paxType === 'ADT') {
      let year = date.getFullYear();
      year = year - 100;
      // console.log(year);
      return { year: year, month: date.getMonth() + 1, day: date.getDate() };
    } else if (paxType === 'CHD') {
      let year = date.getFullYear();
      year = year - 12;
      return { year: year, month: date.getMonth() + 1, day: date.getDate() };
    } else {
      let year = date.getFullYear();
      year = year - 2;
      return { year: year, month: date.getMonth() + 1, day: date.getDate() };
    }
  }

  callMax(paxType: string) {
    let date = new Date();
    if (paxType === 'ADT') {
      let year = date.getFullYear();
      year = year - 12;
      return {
        year: year,
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
    } else if (paxType === 'CHD') {
      let year = date.getFullYear();
      year = year - 1;
      return { year: year, month: date.getMonth() + 1, day: date.getDate() };
    } else {
      let year = date.getFullYear();
      return { year: year, month: date.getMonth() + 1, day: date.getDate() };
    }
  }

  getLoadingLabel(): Observable<any> {
    return this.subject.asObservable();
  }

  setLoadingLabel(action: string) {
    this.subject.next({ text: action });
  }

  addZero(number: number) {
    return number > 9 ? number : `0${number}`;
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // progressbar

  getProgress(): Observable<any> {
    return this.getprogressval.asObservable();
  }

  setProgress(action: any) {
    this.getprogressval.next({ text: action });
  }

  // to pass ordernumber to view details page

  getOrderId(): Observable<any> {
    return this.getorderval.asObservable();
  }

  setOrderId(action: any) {
    this.getorderval.next({ text: action });
  }

  sendActionChildToParent(action: string) {
    this.subject.next({ text: action });

    /*  setTimeout(() => {
      this.subject.next({ text: 'Loading... Please Wait.' });
    },10000); */
  }

  getActionChildToParent(): Observable<any> {
    return this.subject.asObservable();
  }

  setFromToDetails(action: any) {
    this.subject.next(action);
  }

  getFromToDetails(): Observable<any> {
    return this.subject.asObservable();
  }

  setActiveNavItem(action: string) {
    this.activeNavItem.next(action);
  }

  getActiveNavItem(): Observable<string> {
    return this.activeNavItem.asObservable();
  }

  setShoppingSearch(action: ShoppingSearch) {
    this.shoppingSearch.next(action);
  }
  getShoppingSearch(): Observable<ShoppingSearch> {
    return this.shoppingSearch.asObservable();
  }

  getShoppingBrandsNCat(): Observable<BrandsNCat[]> {
    return this.shoppingProducts.asObservable();
  }
  setShoppingBrandsNCat(action: BrandsNCat[]) {
    this.shoppingProducts.next(action);
  }

  getShoppingDropdownData(): Observable<ShoppingDropdownData[]> {
    return this.shoppingDropdown.asObservable();
  }

  setShoppingDropdownData(action: ShoppingDropdownData[]) {
    this.shoppingDropdown.next(action);
  }

  setTimer(action: any) {
    this.setTimerSubject.next({ text: action });
  }

  getTimer(): Observable<any> {
    return this.setTimerSubject.asObservable();
  }

  authenticationToken() {
    /*  this.flightsService
       .CreateOrderNumber('B2C' + new Date().getTime())
       .subscribe(
         (data: any) => {
           if (data && data.orderNumber) {
           } else {
             this.jwtService.removeTempKey();
             this.toastr.error(
               'Your Session has been expired, Please login to continue.',
               'Error'
             );
           }
         },
         (error: any) => {
           this.jwtService.removeTempKey();
           this.toastr.error(
             'Your Session has been expired, Please login to continue.',
             'Error'
           );
         }
       ); */
  }

  logOut() {
    this.sendActionChildToParent('Loading... Please Wait.');
    const userInfo = this.jwtService.loggedUserInfo;
    /*  if (userInfo && userInfo.email) {
      const loginInfo = {
        email: userInfo.email,
      };
      this.usersService.logout().subscribe(
        (data) => {},
        (error) => {}
      );
    } */
  }

  getPageTitle(title: any) {
    this.titleService.setTitle(title);
  }
  public topscroll() {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      600,
    );
    this.authenticationToken();
  }

  /**
   * It needs to called before search for any service.
   * Clear items from local storage.
   */
  public clearItemsFromLocalStorage() {
    localStorage.removeItem('selectedRoom');
    localStorage.removeItem('selectedHotel');
    localStorage.removeItem('hotelSearchInfo');
    localStorage.removeItem('hotelPriceInfo');
    localStorage.removeItem('guestInfo');
    localStorage.removeItem('searchRequest');
    localStorage.removeItem('shoppingSearch');
    localStorage.removeItem('destTransport');
    localStorage.removeItem('umrahFareSummary');
    localStorage.removeItem('HotelRefNumber');
    localStorage.removeItem('tranceId');
    localStorage.removeItem('transportSearchRequest');
    localStorage.removeItem('pgResponse');
    localStorage.removeItem('selectedorderid');
    localStorage.removeItem('virtualHotels');
    localStorage.removeItem('orderInfo');
    localStorage.removeItem('destinationUmrah');
    localStorage.removeItem('contactInfo');
    localStorage.removeItem('visaSearch');
  }

  localUpload(image: any, folderName: string) {
    const extension = image.name.substring(image.name.lastIndexOf('.'));
    let fileName = image.name.replace(image.name.substr(image.name.lastIndexOf('.')), '');
    fileName = fileName.replace(/[.]/g, '');
    let newFileName = fileName.replace(/[.\s]/g, '-') + extension;
    newFileName = newFileName + '###' + folderName;
    const formData = new FormData();
    formData.append('image', image, newFileName);
    return this.httpClient.post(this.baseUrl + 'uploadImage', formData);
  }

  /* Post ticketing list */
  public getPostTicketingList(): Observable<any> {
    try {
      return this.httpClient.get(`${this.postTicketingURL}GetRefundTickets`).pipe(
        map((data) => {
          return data;
        }),
      );
    } catch (error: any) {
      return error;
    }
  }

  /* Get Umrah Packages */
  public getPackages(data: Packages): Observable<any> {
    const ROEStorage = sessionStorage.getItem('ROE');
    const baseROE = sessionStorage.getItem('baseROE');
    this.ROE = ROEStorage ? JSON.parse(ROEStorage as string) : '';
    this.baseROE = baseROE ? JSON.parse(baseROE as string) : '';
    const lang = localStorage.getItem('wajedLang')
      ? JSON.parse(localStorage.getItem('wajedLang') || '{}')
      : {
          languageCode: 'ar',
        };
    try {
      return this.httpClient.post(`${this.fc_URI}OtherServicesB2C/GetPackages`, data).pipe(
        map((data: any) => {
          if (this.ROE && data?.productDetails?.length > 0) {
            const productDetails = data.productDetails;
            productDetails.forEach((productDetail: any) => {
              productDetail.productTypePriceDetails.forEach(
                (productTypeDetails: TransferDetails) => {
                  productTypeDetails.currency = this.ROE.currencyCode;
                  productTypeDetails.totalSellingPrice = this.calculateROE(
                    productTypeDetails.totalSellingPrice,
                    this.ROE.actualROE,
                    this.baseROE.actualROE,
                    2,
                  );
                  productTypeDetails.fares.forEach(
                    (fare: { amount: number }) =>
                      (fare.amount = this.calculateROE(
                        fare.amount,
                        this.ROE.actualROE,
                        this.baseROE.actualROE,
                        2,
                      )),
                  );
                },
              );
            });
          }
          return data;
        }),
      );
    } catch (error: any) {
      return error;
    }
  }

  /**
   * Calculate the Return on Equity (ROE) based on the given amount, ROE, base ROE, and number of decimal places to round to.
   *
   * @param {number} amount - The amount to calculate ROE for
   * @param {number} ROE - The Return on Equity percentage
   * @param {number} baseROE - The base Return on Equity percentage
   * @param {number} toFixed - The number of decimal places to round to
   * @return {Number} The calculated Return on Equity value
   */
  calculateROE(amount: number, ROE: number, baseROE: number, toFixed: number): number {
    return Number(((amount / baseROE) * ROE).toFixed(toFixed));
  }

  public getROES(): Observable<any> {
    try {
      return this.httpClient.get(`${this.fc_URI}ROE/SelectedCountriesROE/ROE`);
    } catch (error: any) {
      return error;
    }
  }

  /**
   * A function to find the substring with the highest sum of numbers when split by commas and plus signs.
   *
   * @param {string} inputString - the input string to be processed
   * @return {string} the substring with the highest sum of numbers
   */
  public showMaxGuests(inputString: string): string {
    //Split the input string by commas
    const substrings = inputString.split(',');

    // Find the highest sum
    let highestSum = -Infinity; // Initialize with negative infinity to handle negative numbers
    let highestString = '';

    substrings.forEach((substring) => {
      // Convert the substring into an array of numbers
      const numbers = substring.split('+').map(Number);

      //  Calculate the sum of the array
      const sum = numbers.reduce((acc, curr) => acc + curr, 0);

      //  Find the maximum sum
      if (sum > highestSum) {
        highestSum = sum;
        highestString = substring;
      }
    });

    return highestString;
  }

  /* Manual Refund */
  public createManualRefund(data: any): Observable<any> {
    try {
      return this.httpClient.post(`${this.postTicketingURL}CreateManualRefund`, data).pipe(
        map((data) => {
          return data;
        }),
      );
    } catch (error: any) {
      return error;
    }
  }
  public postTicketingRefund(data: PostTicketingRefund): Observable<any> {
    try {
      return this.httpClient.post(`${this.postTicketingRefundURL}Refund`, data).pipe(
        map((data) => {
          return data;
        }),
      );
    } catch (error: any) {
      return error;
    }
  }

  public getShoppingDropdown(): Observable<any> {
    try {
      const tId = Tenant.WAJED;
      return this.httpClient.get(`${this.shoppingBaseURL}/${this.shoppingDropdownURL}/${tId}`).pipe(
        map((data) => {
          return data;
        }),
      );
    } catch (error: any) {
      return error;
    }
  }

  public getShoppingProductType(id: number): Observable<any> {
    try {
      const tId = Tenant.WAJED;
      return this.httpClient
        .get(`${this.shoppingBaseURL}/${this.shoppingProductTypeURL}/${id}/${tId}`)
        .pipe(
          map((data) => {
            return data;
          }),
        );
    } catch (error: any) {
      return error;
    }
  }
  public getShoppingProductSubtype(id: number): Observable<any> {
    try {
      const tId = Tenant.WAJED;
      return this.httpClient
        .get(`${this.shoppingBaseURL}/${this.shoppingProductSubTypeURL}/${id}/${tId}`)
        .pipe(
          map((data) => {
            return data;
          }),
        );
    } catch (error: any) {
      return error;
    }
  }

  getFilteredShoppingService(
    serviceType: string,
    data: ShoppingDropdownData[],
  ): ShoppingDropdownData[] {
    return data.filter((item) => item.serviceType === serviceType);
  }

  getShoppingResults(searchPayload: SearchRequestShopping): Observable<ShoppingSearchRes> {
    try {
      const ROEStorage = sessionStorage.getItem('ROE');
      const baseROE = sessionStorage.getItem('baseROE');
      const lang = this.getCurrentLanguage();
      this.ROE = ROEStorage ? JSON.parse(ROEStorage as string) : '';
      this.baseROE = baseROE ? JSON.parse(baseROE as string) : '';
      return this.httpClient
        .post<ShoppingSearchRes>(`${this.shoppingB2cURL}/search`, {
          ...searchPayload,
          tenantId: Tenant.WAJED,
        })
        .pipe(
          map((data) => {
            if (this.ROE && data?.shoppingDetails && data?.shoppingDetails?.length > 0) {
              data.shoppingDetails.forEach((shoppingResult: ShoppingDetails) => {
                shoppingResult.shoppingTypePriceDetails.forEach(
                  (shoppingTypePriceDetails: ShoppingTypePriceDetails) => {
                    shoppingTypePriceDetails.currency = this.ROE.currencyCode;
                    shoppingTypePriceDetails.totalSellingPrice = this.calculateROE(
                      shoppingTypePriceDetails.totalSellingPrice,
                      this.ROE.actualROE,
                      this.baseROE.actualROE,
                      2,
                    );
                    shoppingTypePriceDetails.fares.forEach(
                      (fare: { amount: number }) =>
                        (fare.amount = this.calculateROE(
                          fare.amount,
                          this.ROE.actualROE,
                          this.baseROE.actualROE,
                          2,
                        )),
                    );
                  },
                );
              });
            }
            return data;
          }),
        );
    } catch (error: any) {
      return error;
    }
  }

  getShoppingDetails(shoppingDetailsPayload: {
    traceId: string;
    supplierParameter: string;
  }): Observable<any> {
    return this.httpClient
      .post(`${this.shoppingB2cURL}/GetShoppingDetails`, {
        ...shoppingDetailsPayload,
        tenantId: Tenant.WAJED,
      })
      .pipe(
        map((data) => {
          return data;
        }),
      );
  }
  getShoppingPrice(shoppingPricePayload: ShoppingPricePayload): Observable<any> {
    const ROEStorage = sessionStorage.getItem('ROE');
    const baseROE = sessionStorage.getItem('baseROE');
    const lang = this.getCurrentLanguage();
    this.ROE = ROEStorage ? JSON.parse(ROEStorage as string) : '';
    this.baseROE = baseROE ? JSON.parse(baseROE as string) : '';
    return this.httpClient
      .post(`${this.shoppingB2cURL}/price`, {
        ...shoppingPricePayload,
        tenantId: Tenant.WAJED,
      })
      .pipe(
        map((data: any) => {
          if (this.ROE && data?.shoppingTypes && data.shoppingTypes.length > 0) {
            const shoppingPriceRes: ShoppingPriceResponse = data;

            shoppingPriceRes.shoppingTypes.forEach((shoppingType: ShoppingTypes) => {
              shoppingType.shoppingTypeDetails.forEach(
                (shoppingTypeDetails: ShoppingTypeDetailsRes) => {
                  shoppingTypeDetails.shoppingTypePriceDetail.currency = this.ROE.currencyCode;
                  shoppingTypeDetails.shoppingTypePriceDetail.totalSellingPrice = this.calculateROE(
                    shoppingTypeDetails.shoppingTypePriceDetail.totalSellingPrice,
                    this.ROE.actualROE,
                    this.baseROE.actualROE,
                    2,
                  );
                  shoppingTypeDetails.shoppingTypePriceDetail.fares.forEach(
                    (fare: { amount: number }) =>
                      (fare.amount = this.calculateROE(
                        fare.amount,
                        this.ROE.actualROE,
                        this.baseROE.actualROE,
                        2,
                      )),
                  );
                },
              );
            });
          }

          return data;
        }),
      );
  }

  shoppingTicketOrder(ticketOrderPayload: TicketOrderPayload) {
    return this.httpClient.post(`${this.shoppingB2cURL}/TicketOrder`, ticketOrderPayload);
  }

  createOrderNumberShopping(traceId: string): Observable<any> {
    return this.httpClient.get(`${this.shoppingB2cURL}/CreateOrderNumber/${traceId}`);
  }

  createBookingShopping(bookingPayload: ShoppingBookingPayload): Observable<any> {
    return this.httpClient
      .post(`${this.shoppingB2cURL}/book`, bookingPayload)
      .pipe(map((data) => data));
  }

  getOrderDetailsShopping(orderDetailsPayload: GetOrderDetails): Observable<any> {
    return this.httpClient.get(
      `${this.shoppingB2cURL}/GetOrderDetails/${orderDetailsPayload.orderNumber}/${orderDetailsPayload.traceId}`,
    );
  }

  getStaticToken() {
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPVFBiMmJ1c2VyMkBnbWFpbC5jb20iLCJqdGkiOiI4MDdkYmRjOC05N2JhLTQwMDgtYmRlNS1hMWQwY2RjZTU4OTciLCJpYXQiOjE3MDEwODU3NDAsInRJZCI6IjIxIiwiY0lkIjoiMTAxOSIsImJJZCI6IjEwMzUiLCJ1SWQiOiIxMDY5Iiwic0lkIjpbIjAiLCIwIl0sImNiSWQiOiIwIiwidVQiOiJDbGllbnQiLCJpZCI6ImNlMDc1NGVlLWJiYWUtNGIwYS1iZDJkLTA3Yjk4ZTllZGZjNiIsInBOIjoiT3RwIFVzZXIyIiwicFQiOiJCMkIiLCJySWRzIjoiIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQ2xpZW50QWRtaW4iLCJuYmYiOjE3MDEwODU3NDAsImV4cCI6MTcwMzcxMzc0MCwiaXNzIjoiVXNlck1hbmFnZW1lbnRBUEkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUwMDAvIn0.F8sgEzkWDGbIGTuyQRXkycdqpXuYl3IwHOU9iDeiqdc';
  }

  ///////////////////////// MULTI ROOM FUNCTIONS //////////////////////////////////////////
  getTotalSelectedRooms(roomsArray: SelectedRoom[][]): number {
    let totalSelectedRooms = 0;
    roomsArray.forEach((room) => {
      totalSelectedRooms += room.reduce(
        (total: number, roomDetails: { roomTypeQuantity: number }): number =>
          total + roomDetails.roomTypeQuantity,
        0,
      );
    });
    return totalSelectedRooms;
  }

  checkRoomExist(
    selectedRooms: SelectedRooms,
    searchIn: string,
    room: { roomId: number; boardType: string },
  ) {
    return selectedRooms[searchIn].some(
      (sr: { roomId: number; boardType: string }) =>
        sr.roomId === room.roomId && sr.boardType === room.boardType,
    );
  }

  onClickConfirm({
    selectedRooms,
    isMaxRoomSelected,
    searchRequest,
    formDetailsRoute,
  }: onClickConfirm) {
    const selectedRoomLength = Object.values(selectedRooms).length;
    const flattedSelectedRooms = Object.values(selectedRooms).flat(1);
    debugger;
    const isZeroAdults = flattedSelectedRooms.some(
      (room: SelectedRoom) => room.adults.adults === '',
    );

    if (isZeroAdults) {
      const translatedValues = this.translate.instant(['validation', 'okay', 'adultsCannotBeZero']);
      Swal.fire({
        icon: 'warning',
        title: translatedValues.validation,
        confirmButtonText: translatedValues.okay,
        text: translatedValues.adultsCannotBeZero,
      });
      return;
    }

    if (selectedRoomLength === 0) {
      const translatedValues = this.translate.instant([
        'selectARoom',
        'okay',
        'youHaveNotSelectedAnyRooms',
      ]);
      Swal.fire({
        icon: 'info',
        timer: 4000,
        timerProgressBar: true,
        title: translatedValues.selectARoom,
        text: translatedValues.youHaveNotSelectedAnyRooms,
        confirmButtonText: translatedValues.okay,
      });
      return;
    }

    //show a warning to user when user has not selected same room as he searched for
    if (!isMaxRoomSelected) {
      const translatedValues = this.translate.instant([
        'editSelection',
        'yesContinue',
        'doYouWantToContinue',
        'youSearchedFor',
      ]);
      const message = translatedValues.doYouWantToContinue.replace(
        '$',
        this.translate.instant(searchRequest.guests.length.toString()),
      );

      Swal.fire({
        icon: 'info',
        title: translatedValues.doYouWantToContinue,
        text: message,
        confirmButtonText: translatedValues.yesContinue,
        cancelButtonText: translatedValues.editSelection,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('selectedRoom', JSON.stringify(selectedRooms));
          this.router.navigateByUrl(formDetailsRoute);
        }
      });

      return;
    }
    localStorage.setItem('selectedRoom', JSON.stringify(selectedRooms));
    this.router.navigateByUrl(formDetailsRoute);
  }

  public checkMaxRoomSelected(selectedRooms: SelectedRooms, searchRequest: SearchRequest): boolean {
    const selectedRoomLength = Object.values(selectedRooms).length;
    const totalSelectedRooms = this.getTotalSelectedRooms(Object.values(selectedRooms));
    let isMaxRoomSelected = false;

    if (
      selectedRoomLength === searchRequest.guests.length ||
      totalSelectedRooms === searchRequest.guests.length
    ) {
      isMaxRoomSelected = true;
    } else {
      isMaxRoomSelected = false;
    }

    return isMaxRoomSelected;
  }

  //////////////////////////////////////////////////////////////////////////

  createTraceId(): string {
    return 'Alrajhi_' + Math.random().toString(36).slice(2);
  }

  getTranslatedText(text: string): Observable<any> {
    const lang = this.getCurrentLanguage();
    return this.httpClient.post(`${this.BASE_URL}Translator/Translate`, JSON.stringify(text), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

type Packages = {
  traceId: string;
  offset: number;
  fetch: number;
  serviceType: 'umrah' | 'packages';
};

export type Fare = {
  paxType: string;
  amount: number;
  fareTag: string;
};

type PostTicketingRefund = {
  traceId: string;
  consolidationWaitTime: number;
  refundRequest: RefundRequest[];
};

type DownloadInvoicePDF = {
  Hotel: string;
  Umrah: string;
  Hajj: string;
  Packages: string;
  Transfer: string;
  Shopping: string;
};

type onClickConfirm = {
  selectedRooms: SelectedRooms;
  isMaxRoomSelected: boolean;
  searchRequest: SearchRequest;
  formDetailsRoute: string;
};

type SearchRequest = {
  guests: Guest[];
};

export type CreateOrderNumberRes = {
  orderNumber: string;
  traceId: string;
  errors: ErrorDetails[];
};

export type ErrorDetails = [
  {
    errorCode: string;
    errorDetail: string;
  },
];

export type TicketOrderPayload = {
  paymentMethod: string;
  traceId: string;
  orderNumber: string;
  shoppingRefNo: string;
};

export type GetOrderDetails = {
  traceId: string;
  orderNumber: string;
};

export type CurrentLanguage = {
  languageCode: 'ar' | 'en';
};

type BrandsNCat = Brands;
