import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { User } from '../models/user';
import { GlobalService, JwtService, UsersService } from 'src/app/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  userDetails: any;
  public userSubject?: BehaviorSubject<User>;
  public user1: Observable<User> | undefined;
  user: any;
  isRefreshingToken: any;
  private traceId = Math.random().toString(36).slice(2);

  constructor(
    private authenticationService: UsersService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private jwtService: JwtService,
    private globalService: GlobalService,
  ) {
    this.user = JSON.parse(localStorage.getItem('userData') || '{}');
    this.userSubject = new BehaviorSubject<User>({});
    this.user1 = this.userSubject.asObservable();
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newReq = req.clone();
    let token;

    const user = this.authenticationService.userValue;
    const isLoggedIn = user && user.jwtToken;

    if (user != null) {
      token = user.jwtToken;
    } else if (this.user != undefined) {
      token = this.user.accessToken.token;
    }

    return next.handle(this.addToken(req, token)).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.spinner.hide();
          if (err.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session expired',
              confirmButtonText: 'Okay',
            }).then((res) => {
              if (res.isConfirmed) {
                this.removeKey();
                // this.router.navigateByUrl("/");
              }
            });
          }
        }
        const error = (err && err.error && err.error.message) || err.statusText;
        return throwError(error);
      }),
    );
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const lang = this.globalService.getCurrentLanguage();
    if (
      req.url.includes('http://devcrmpopservice.benlabs.in') ||
      req.url.includes('https://devcrmpopservice.benlabs.in')
    ) {
      req = req.clone({
        setHeaders: {
          // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPVFBiMmJ1c2VyMkBnbWFpbC5jb20iLCJqdGkiOiI5YWJiNmIyNy1iYTFhLTQ1NDgtOTUyZS05OGE4NmJlZWMyNzAiLCJpYXQiOjE2NjYxODY5MTEsInRJZCI6IjIxIiwiY0lkIjoiMTAxOSIsImJJZCI6IjEwMzUiLCJ1SWQiOiIxMDY5IiwidVQiOiJDbGllbnQiLCJpZCI6ImNlMDc1NGVlLWJiYWUtNGIwYS1iZDJkLTA3Yjk4ZTllZGZjNiIsInBOIjoiT3RwIFVzZXIyIiwicFQiOiJCMkIiLCJySWRzIjoiIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQ2xpZW50QWRtaW4iLCJuYmYiOjE2NjYxODY5MTEsImV4cCI6MTY2ODgxNDkxMSwiaXNzIjoiVXNlck1hbmFnZW1lbnRBUEkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUwMDAvIn0.puNIdytUtJDA3A7Ryek1EuAIDte46RTgsak4cXC2MRw`,
          Authorization: this.jwtService.getStaticToken()
            ? `Bearer ${this.jwtService.getStaticToken()}`
            : '',
          'Accept-Language': lang.languageCode,
        },
      });

      req = req.clone({
        setHeaders: {
          Authorization: this.jwtService.getToken() ? `Bearer ${this.jwtService.getToken()}` : '',
          'Accept-Language': lang.languageCode,
        },
      });
    }
    return req;
  }

  removeKey() {
    this.jwtService.removeTempKey();
    this.globalService.sendActionChildToParent('Loading... Please Wait.');
  }
}
