import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService, JwtService } from '..';
import { environment } from '../../../environments/environment';
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  alreadycheckRun = true;
  constructor(public jwtService: JwtService, public globalService: GlobalService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lang = this.globalService.getCurrentLanguage();
    // ;
    if (
      request.url.includes('http://devcrmpopservice.benlabs.in') ||
      request.url.includes('https://devcrmpopservice.benlabs.in')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: this.jwtService.getToken() ? `Bearer ${this.jwtService.getToken()}` : '',
          'Accept-Language': lang.languageCode,
          // Authorization:
          // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPVFBiMmJ1c2VyMkBnbWFpbC5jb20iLCJqdGkiOiI4MDdkYmRjOC05N2JhLTQwMDgtYmRlNS1hMWQwY2RjZTU4OTciLCJpYXQiOjE3MDEwODU3NDAsInRJZCI6IjIxIiwiY0lkIjoiMTAxOSIsImJJZCI6IjEwMzUiLCJ1SWQiOiIxMDY5Iiwic0lkIjpbIjAiLCIwIl0sImNiSWQiOiIwIiwidVQiOiJDbGllbnQiLCJpZCI6ImNlMDc1NGVlLWJiYWUtNGIwYS1iZDJkLTA3Yjk4ZTllZGZjNiIsInBOIjoiT3RwIFVzZXIyIiwicFQiOiJCMkIiLCJySWRzIjoiIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQ2xpZW50QWRtaW4iLCJuYmYiOjE3MDEwODU3NDAsImV4cCI6MTcwMzcxMzc0MCwiaXNzIjoiVXNlck1hbmFnZW1lbnRBUEkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUwMDAvIn0.F8sgEzkWDGbIGTuyQRXkycdqpXuYl3IwHOU9iDeiqdc',
        },
      });
    } else if (
      !request.url.includes('http://devcrmpopservice.benlabs.in') ||
      !request.url.includes('https://devcrmpopservice.benlabs.in')
    ) {
      // if (request.url.includes(environment.API_Umrah_Base_URL)) {

      //   request = request.clone({
      //     setHeaders: {
      //       Authorization: environment.Other_Service_Token,
      //     },
      //   });
      // } else
      if (!this.jwtService.getToken()) {
        request = request.clone({
          setHeaders: {
            'Accept-Language': lang.languageCode,
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
            // Authorization: `${this.jwtService.getToken()}`,
            Authorization: `Bearer ${this.jwtService.getToken()}`,
            'Accept-Language': lang.languageCode,
          },
        });
      }
    }
    if (this.jwtService.getToken() && this.alreadycheckRun) {
      this.alreadycheckRun = false;
      this.globalService.authenticationToken();
    }
    return next.handle(request);
  }
}
