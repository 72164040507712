import { ChangeDetectorRef, Component } from '@angular/core';
import { GlobalService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AlrajhiUmrah';

  constructor(private globalService: GlobalService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setCurrencyOnPageReload();
  }

  setCurrencyOnPageReload() {
    const selectedCurrency = localStorage.getItem('wajedCurrency')
      ? JSON.parse(localStorage.getItem('wajedCurrency') as string).name
      : '';
    if (selectedCurrency !== '') {
      this.globalService.getROES().subscribe((resp: any) => {
        let v: any;
        v = selectedCurrency.hasOwnProperty('value')
          ? selectedCurrency.value.name
          : selectedCurrency;
        if (resp) {
          const selectedROE = resp?.rateOfExchange?.rateOfExchanges?.find(
            (roe: any) => roe.currencyCode === v,
          );
          if (selectedCurrency) {
            sessionStorage.setItem('ROE', JSON.stringify(selectedROE));

            const selectedBaseROE = resp?.rateOfExchange?.rateOfExchanges?.find(
              (roe: any) => roe.currencyCode === 'SAR',
            );

            if (selectedBaseROE) {
              sessionStorage.setItem('baseROE', JSON.stringify(selectedBaseROE));
            }
          }
        }
      });
    }
  }
}

export type ShoppingDropdownData = {
  city: string;
  country: string;
  countryCode: string;
  id: number;
  name: string;
  profileImage: string;
  profileImageArr: null | any[];
  profileImageBase64: null | string;
  serviceType?: string;
};
